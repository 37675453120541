html,
body {
  height: 100%;
  @supports(height: 100dvh) {
    height: 100dvh;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--typography-100);
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  min-height: 100%;
  display: flex;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

ul,
ol {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:hover {
  text-decoration: none;
}

button {
  background: none;
}

button,
input[type='submit'],
input[type='file'],
input[type='button'] {
  border-radius: 0;
  color: inherit;
  padding: 0;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

input,
button,
textarea,
select {
  letter-spacing: inherit;
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  border: none;
}
